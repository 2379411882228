import React from 'react'

import Hero from 'components/home/Hero'
import Disclaimer from 'components/home/Disclaimer'
import Position from 'components/home/Position'
import MetaTags from 'components/MetaTags'

const Home = () => (
  <>
    <MetaTags title="My Vape Regs" />
    <Hero />
    <Disclaimer />
    <Position />
  </>
)

export default Home
