import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { media } from 'styles/media'
import { fontWeights } from 'styles/typography'
import { white } from 'styles/colors'
import {
  navHeight,
  navHeightMobile,
  animationTime,
  animationCurve,
} from 'styles/variables'

import Wrapper from 'components/Wrapper'
import MobileNav from 'components/MobileNav'
import NavItem from './NavItem'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  transition: background-color ${animationTime} ${animationCurve};
  z-index: 400;
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${navHeightMobile};

  ${media.medium`
    height: ${navHeight};
  `};
`

const Logo = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  color: ${white};
  transition: color ${animationTime} ${animationCurve};

  ${media.medium`
    font-size: 20px;
  `};

  ${media.large`
    font-size: 21px;
  `};

  ${media.xLarge`
    font-size: 24px;
  `};

  &:after {
    content: '';
    position: absolute;
    bottom: 5%;
    left: -2px;
    right: -2px;
    height: 45%;
    z-index: -1;
    background-color: #02828a;
  }
`

const List = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

// const LangSwitcher = styled.a`
//   color: ${white};
//   font-weight: ${fontWeights.medium};
//   margin-left: ${spacing.large};
//   color: ${white};
//   transition: color ${animationTime} ${animationCurve};
// `

const Nav = () => (
  <Container>
    <Wrapper>
      <Inner>
        <Logo to="/">My Vape Regs</Logo>

        <List className="u-hide-medium-down">
          <NavItem
            label="Western Canada"
            subLinks={[
              {
                label: 'British Columbia',
                link: '/bc/',
              },
              {
                label: 'Alberta',
                link: '/alberta/',
                disabled: true,
              },
              {
                label: 'Saskatchewan',
                link: '/saskatchewan/',
                disabled: true,
              },
              {
                label: 'Manitoba',
                link: '/manitoba/',
                disabled: true,
              },
            ]}
          />
          <NavItem
            label="Central Canada"
            subLinks={[
              {
                label: 'Ontario',
                link: '/ontario/',
              },
              {
                label: 'Quebec',
                link: '/quebec/',
                disabled: true,
              },
            ]}
          />
          <NavItem
            label="Atlantic Canada"
            subLinks={[
              {
                label: 'New Brunswick',
                link: '/new-brunswick/',
                disabled: true,
              },
              {
                label: 'Prince Edward Island',
                link: '/prince-edward-island/',
              },
              {
                label: 'Nova Scotia',
                link: '/nova-scotia/',
              },
              {
                label: 'Newfoundland and Labrador',
                link: '/newfoundland-and-labrador/',
                disabled: true,
              },
            ]}
          />
        </List>
        <MobileNav />
        {/* <LangSwitcher href="#">FR</LangSwitcher> */}
      </Inner>
    </Wrapper>
  </Container>
)

export default Nav
