import React from 'react'
import styled from 'styled-components'

import { media } from 'styles/media'
import { white } from 'styles/colors'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Heading from 'components/Heading'

const Container = styled.div`
  background-color: #353f46;
  color: ${white};
`

const StyledHeading = styled(Heading)`
  color: ${white};
  margin-bottom: 0.75em;

  ${media.medium`
    margin-bottom: 1em;
  `};
`

const Position = () => (
  <Container>
    <Section>
      <Wrapper>
        <StyledHeading size={2}>Our Position</StyledHeading>
        <p>
          Imperial Tobacco Canada supports fact-based regulations that balance
          the need to give adult smokers access to viable and less-harmful
          alternatives to cigarettes while ensuring that vaping products are
          kept out of the hands of youth.
        </p>
        <p>
          The federal government has already put in place a regulatory framework
          for vaping products that includes limits on nicotine levels, flavour
          restrictions, advertising restrictions, packaging requirements and a
          ban on sales to minors. These laws should be strictly enforced and
          anyone who breaks them should be severely punished.
        </p>
        <p>
          As seen in other countries, vaping products have the potential to play
          an important role in achieving the federal government's objective of
          reducing the smoking rate to five percent by 2035. But, in order to be
          successful, there needs to be appropriate regulations that allows and
          supports communication to adult smokers, that strictly enforces
          current laws and regulations, that makes products viable to adult
          smokers that wish to switch from cigarettes, and that implements
          consistent product standards. A patchwork of excessive provincial
          regulations will not allow these products to achieve their intended
          harm-reduction objective.
        </p>
      </Wrapper>
    </Section>
  </Container>
)

export default Position
