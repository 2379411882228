import React from 'react'

import ProvinceHeader from 'components/ProvinceHeader'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import MetaTags from 'components/MetaTags'
import ChangesList from 'components/ChangesList'

const Ontario = () => (
  <>
    <MetaTags title="Ontario | My Vape Regs" />
    <ProvinceHeader name="Ontario" slug="ontario" />
    <Section>
      <Wrapper>
        <ChangesList
          name="Ontario"
          date="July 1, 2020"
          items={[
            'Flavoured vaping products – other than tobacco, mint and menthol flavours – may only be sold online and in specialty vape stores. Tobacco, mint and menthol flavoured products can continue to be sold in other retail establishments.',
            'Vaping products with a concentration of nicotine greater that 20 mg/mL may only be sold online and in specialty vape stores. Products with nicotine concentrations up to 20 mg/mL can continue to be sold in other retail establishments.',
          ]}
        />
      </Wrapper>
    </Section>
  </>
)

export default Ontario
