import React from 'react'

import ProvinceHeader from 'components/ProvinceHeader'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import MetaTags from 'components/MetaTags'
import ChangesList from 'components/ChangesList'

const BritishColumbia = () => (
  <>
    <MetaTags title="British Columbia | My Vape Regs" />
    <ProvinceHeader name="British Columbia" slug="bc" />
    <Section>
      <Wrapper>
        <ChangesList
          name="BC"
          date="July 20, 2020"
          items={[
            'Effective July 20, 2020: Flavoured vaping products – other than tobacco flavours – may only be sold online and in adult-only locations. Tobacco flavoured products and unflavoured products can continue to be sold in other retail establishments. Retailers may continue to sell their current inventory of flavoured products until September 15, 2020.',
            'Effective July 20 2020: Vaping products with a concentration of nicotine greater that 20 mg/mL are banned. Retailers may continue to sell their current inventory of products with higher nicotine products until September 15, 2020.',
            'Effective July 20 2020: Nicotine-free vaping products are banned.',
            'Effective July 20 2020: Vaping products cannot be sold to a consumer unless they are packaged in plain packaging. Retailers may continue to sell their current inventory of products with older packaging until September 15, 2020',
            'Effective July 20 2020: Refillable containers of vaping e-liquids must contain less that 30mL of e-liquid, and tanks or cartridges must have a capacity of less than 2mL of e-liquid. Retailers may continue to sell their current inventory of higher capacity products until September 15, 2020.',
          ]}
        />
      </Wrapper>
    </Section>
  </>
)

export default BritishColumbia
