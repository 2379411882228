import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { string, object, bool } from 'prop-types'
import { rgba } from 'polished'

import { white } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { cover, boxShadow } from 'styles/helpers'
import { media } from 'styles/media'
import { fontWeights } from 'styles/typography'

import Heading from 'components/Heading'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'

const Container = styled.div`
  position: relative;
  background-color: #eff1f3;
  text-align: center;
  overflow: hidden;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
`

const StyledHeading = styled(Heading)`
  font-weight: ${fontWeights.regular};
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  margin-bottom: ${spacing.large};
  margin-top: -${spacing.large};

  &:after {
    content: '';
    display: block;
    width: 48px;
    height: 1px;
    background-color: currentColor;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${spacing.medium};

    ${media.small`
      width: 60px;
    `};

    ${media.medium`
      width: 80px;
      margin-top: ${spacing.large};
    `};

    ${media.large`
      width: 90px;
    `};

    ${media.xLarge`
      width: 108px;
    `};
  }

  ${media.small`
    margin-bottom: ${spacing.xLarge};
  `};

  ${media.medium`
    max-width: 700px;
    margin-top: -${spacing.xLarge};
  `};

  ${media.large`
    max-width: 720px;
    margin-bottom: ${spacing.xxLarge};
    margin-top: -${spacing.xxLarge};
  `};

  ${media.xLarge`
    margin-bottom: 48px;
    margin-top: -48px;
  `};

  strong {
    font-weight: ${fontWeights.bold};
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: ${spacing.medium};
  grid-template-columns: repeat(2, 1fr);

  ${media.small`
    grid-template-columns: repeat(3, 1fr);
  `};

  ${media.medium`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${media.large`
    grid-gap: ${spacing.large};
    grid-template-columns: repeat(5, 1fr);
  `};
`

const ProvinceContainer = styled(Link)`
  position: relative;
  display: block;
  padding: ${spacing.small};
  text-align: center;
  background-color: ${white};
  color: #02828a;
  ${boxShadow};

  ${props =>
    props.disabled &&
    css`
      background-color: #eff1f3;
      color: ${rgba('#02828a', 0.4)};
      pointer-events: none;
    `}

  ${props =>
    props.active &&
    css`
      &:before {
        content: '';
        border: 2px solid #33a5a7;
        ${cover('absolute')};
      }
    `}

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 85%;
  }
`

const ProvinceName = styled.span`
  text-transform: uppercase;

  font-weight: ${fontWeights.bold};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spacing.small};
  ${cover('absolute')};

  ${media.xLarge`
    padding: ${spacing.medium};
  `};
`

const Background = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.07;
  height: 120%;

  ${media.xLarge`
    width: 1300px;
    height: auto;
  `};
`

const Provinces = ({ location }) => (
  <Container>
    <Section>
      <Content>
        <Wrapper>
          <StyledHeading size={3}>
            <strong>Click on the provinces</strong> in the map below to see new
            or proposed vapour regulations.
          </StyledHeading>
          <Grid>
            <Province slug="bc" name="British Columbia" location={location} />
            <Province
              slug="alberta"
              name="Alberta"
              location={location}
              disabled
            />
            <Province
              slug="saskatchewan"
              name="Saskatchewan"
              location={location}
              disabled
            />
            <Province
              slug="manitoba"
              name="Manitoba"
              location={location}
              disabled
            />
            <Province slug="ontario" name="Ontario" location={location} />
            <Province
              slug="quebec"
              name="Quebec"
              location={location}
              disabled
            />
            <Province
              slug="new-brunswick"
              name="New Brunswick"
              location={location}
              disabled
            />
            <Province
              slug="prince-edward-island"
              name="Prince Edward Island"
              location={location}
            />
            <Province
              slug="nova-scotia"
              name="Nova Scotia"
              location={location}
            />
            <Province
              slug="newfoundland-and-labrador"
              name="Newfoundland and Labrador"
              location={location}
              disabled
            />
          </Grid>
        </Wrapper>
      </Content>
    </Section>
    <Background
      src={require(`assets/images/canada.png`)}
      className="u-hide-medium-down"
      alt=""
    />
  </Container>
)

Provinces.propTypes = {
  location: object,
}

const Province = ({ slug, name, disabled, location }) => {
  const isActive = location.pathname.includes(slug)
  return (
    <ProvinceContainer to={`/${slug}/`} disabled={disabled} active={isActive}>
      <ProvinceName>{name}</ProvinceName>
    </ProvinceContainer>
  )
}

Province.propTypes = {
  slug: string.isRequired,
  name: string.isRequired,
  disabled: bool,
  location: object,
}

export default Provinces
