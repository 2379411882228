import React from 'react'

import ProvinceHeader from 'components/ProvinceHeader'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import MetaTags from 'components/MetaTags'
import ChangesList from 'components/ChangesList'

const NovaScotia = () => (
  <>
    <MetaTags title="Nova Scotia | My Vape Regs" />
    <ProvinceHeader name="Nova Scotia" slug="nova-scotia" />
    <Section>
      <Wrapper>
        <ChangesList
          name="NS"
          date="April 1, 2020"
          items={[
            'Effective April 1, 2020: The sale of flavoured vaping products will be banned, with the exception of tobacco flavours.',
            'Effective September 1, 2020: The sale of vaping products with a concentration of nicotine greater that 20 mg/mL will be banned',
            'Effective September 15, 2020: Vaping products will be subject to a new provincial tax. For vaping liquids, this tax will be $0.50 per millilitre. For vaping devices and components, the new tax will be 20% of the product’s recommended retail selling price.',
          ]}
        />
      </Wrapper>
    </Section>
  </>
)

export default NovaScotia
