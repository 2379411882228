import React from 'react'

import ProvinceHeader from 'components/ProvinceHeader'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import MetaTags from 'components/MetaTags'
import ChangesList from 'components/ChangesList'

const PrinceEdwardIsland = () => (
  <>
    <MetaTags title="Prince Edward Island | My Vape Regs" />
    <ProvinceHeader name="Prince Edward Island" slug="prince-edward-island" />
    <Section>
      <Wrapper>
        <ChangesList
          name="PEI"
          date="March 1, 2020"
          items={[
            'Vaping products may only be sold to people who are 21 or older (except for those who turned 19 prior to March 1, 2020)',
            'Vaping products may only be sold in a “tobacconist shop” (a shop whose primary business is the sale of tobacco products, vaping products, or both).',
          ]}
        />
      </Wrapper>
    </Section>
  </>
)

export default PrinceEdwardIsland
