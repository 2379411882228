import React from 'react'
import styled from 'styled-components'
import { array, string } from 'prop-types'
import { rgba } from 'polished'

import Heading from 'components/Heading'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { white } from 'styles/colors'
import { fontWeights } from 'styles/typography'

const Header = styled.header`
  margin-bottom: ${spacing.xLarge};

  ${media.medium`
    margin-bottom: ${spacing.xxLarge};
  `};

  ${media.large`
    margin-bottom: 48px;
  `};

  ${media.xLarge`
    margin-bottom: ${spacing.xxxLarge};
  `};
`

const StyledHeading = styled(Heading)`
  color: ${white};
  margin-bottom: 0.5em;
`

const Subheading = styled(Heading)`
  color: ${white};
  text-transform: uppercase;
  font-weight: ${fontWeights.regular};
`

const List = styled.div`
  display: block;
  color: ${white};
`

const Item = styled.div`
  display: flex;
  align-items: flex-start;

  ${media.medium`
    align-items: center;
  `};

  & + & {
    margin-top: ${spacing.large};

    ${media.medium`
      margin-top: ${spacing.xLarge};
    `};

    ${media.large`
      margin-top: ${spacing.xxLarge};
    `};
  }
`

const Number = styled(Heading)`
  color: ${rgba(white, 0.25)};
  margin-bottom: 0;
  font-weight: ${fontWeights.bold};
  width: 40px;
  flex-shrink: 0;
  margin-right: ${spacing.medium};

  ${media.small`
    margin-right: ${spacing.large};
    width: 50px;
  `};

  ${media.medium`
    font-size: 72px;
    width: 96px;
    margin-right: ${spacing.xLarge};
  `};

  ${media.large`
    font-size: 84px;
    width: 110px;
  `};

  ${media.xLarge`
    font-size: 100px;
    width: 130px;
  `};
`

const ChangesList = ({ name, items, date }) => (
  <>
    <Header>
      <StyledHeading size={2}>
        Recent changes to {name} vaping laws
      </StyledHeading>
      <Subheading size={3}>Effective {date}:</Subheading>
    </Header>
    <List>
      {items.map((item, i) => (
        <Item key={i}>
          <Number size={1}>0{i + 1}</Number>
          <span>{item}</span>
        </Item>
      ))}
    </List>
  </>
)

ChangesList.propTypes = {
  name: string.isRequired,
  items: array.isRequired,
  date: string.isRequired,
}

export default ChangesList
