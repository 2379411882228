import React from 'react'
import styled, { css } from 'styled-components'
import { string, array, func } from 'prop-types'
import { Link } from 'gatsby'
import { stripUnit } from 'polished'

import { primaryColor } from 'styles/colors'
import { fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'

import SmallCaps from 'components/SmallCaps'

const Container = styled.div`
  position: relative;

  & + & {
    margin-top: ${spacing.large};
  }
`

const Label = styled(SmallCaps)`
  display: block;
  color: ${primaryColor};
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const Menu = styled.div`
  position: static;
`

const Item = styled(Link)`
  display: block;
  font-weight: ${fontWeights.semibold};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}

  & + & {
    margin-top: ${spacing.tiny};
  }
`

const MobileNavItem = ({ label, onClick, subLinks }) => (
  <Container>
    <Label>{label}</Label>
    {subLinks && (
      <Menu>
        {subLinks.map((subLink, i) => (
          <Item
            key={i}
            to={subLink.link}
            onClick={onClick}
            disabled={subLink.disabled}
          >
            {subLink.label}
          </Item>
        ))}
      </Menu>
    )}
  </Container>
)

MobileNavItem.propTypes = {
  link: string.isRequired,
  label: string.isRequired,
  subLinks: array,
  onClick: func,
}

export default MobileNavItem
