import React from 'react'
import styled from 'styled-components'
import { stripUnit } from 'polished'

import { teal, white } from 'styles/colors'
import { media } from 'styles/media'
import { verticalPadding } from 'styles/helpers'
import { fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'

import Logo from 'assets/images/logo.svg'

import Wrapper from 'components/Wrapper'

const Container = styled.footer`
  background-color: ${teal};
  text-align: center;
  display: block;
  color: ${white};
  font-weight: ${fontWeights.semibold};
  ${verticalPadding`32px`};

  a {
    color: inherit;
    text-decoration: underline;
  }

  ${media.small`
    ${verticalPadding`40px`};
  `};

  ${media.medium`
    ${verticalPadding`56px`};
  `};

  ${media.large`
    ${verticalPadding`64px`};
  `};

  ${media.xLarge`
    ${verticalPadding`80px`};
  `};
`

const Inner = styled.div`
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;

  ${media.medium`
    max-width: 700px;
  `};

  ${media.large`
    max-width: 760px;
  `};
`

const StyledLogo = styled(Logo)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing.large};
  width: 100px;
  height: 56px;
  fill: ${white};

  ${media.medium`
    margin-bottom: ${spacing.xLarge};
    width: 120px;
    height: 67px;
  `};

  ${media.large`
    width: 140px;
    height: 79px;
  `};

  ${media.xLarge`
    margin-bottom: ${spacing.xxLarge};
    width: 160px;
    height: 90px;
  `};
`

const FactsLogo = styled.img`
  width: 110px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.small};

  ${media.medium`
    width: 120px;
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  `};

  ${media.large`
    width: 140px;
  `};
`

const VuseLogo = styled.img`
  width: 88px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.small};

  ${media.medium`
    width: 100px;
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  `};

  ${media.large`
    width: 120px;
  `};
`

const Footer = () => (
  <Container>
    <Wrapper>
      <Inner>
        <StyledLogo />
        <p>
          For more information on the issues surrounding responsible vaping
          regulation, visit Imperial Tobacco Canada's{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://factsnotfear.ca/"
          >
            <FactsLogo
              src={require(`assets/images/facts-not-fear-logo.png`)}
              alt="Facts Not Fear logo"
            />
          </a>
        </p>
        <p>
          For more information on Imperial Tobacco Canada’s vaping products
          visit{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.vuse.com/ca/en"
          >
            <VuseLogo src={require(`assets/images/vuse-logo.png`)} alt="Vuse" />
          </a>
        </p>
      </Inner>
    </Wrapper>
  </Container>
)

export default Footer
