import React from 'react'
import styled from 'styled-components'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { white } from 'styles/colors'
import { fontWeights } from 'styles/typography'

import Wrapper from 'components/Wrapper'

const Container = styled.div`
  position: relative;
  z-index: 10;
  color: ${white};
  font-weight: ${fontWeights.semibold};
  margin-top: -${spacing.xLarge};

  ${media.small`
    margin-top: -${spacing.xxxLarge};
  `};

  ${media.medium`
    margin-top: -64px;
    margin-bottom: -${spacing.medium};
  `};

  ${media.large`
    margin-top: -80px;
    margin-bottom: -${spacing.large};
  `};

  ${media.xLarge`
    margin-top: -100px;
    margin-bottom: -${spacing.xLarge};
  `};
`

const Inner = styled.div`
  padding: ${spacing.xLarge} ${spacing.large};
  box-shadow: 1px 2px 19px rgba(0, 0, 0, 0.25);
  background-color: #353f46;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;

  ${media.small`
    padding: ${spacing.xxLarge} ${spacing.xLarge};
  `};

  ${media.medium`
    padding: 48px 56px;
  `};

  ${media.large`
    padding: 54px 64px;
  `};

  ${media.xLarge`
    padding: 60px 72px;
  `};
`

const Disclaimer = () => (
  <Container>
    <Wrapper>
      <Inner>
        <p>
          This website will give you the latest information on regulations that
          affect vapour products. Province by province, your choices are being
          threatened and increased limitations are being imposed. This website
          will help you stay informed.
        </p>
      </Inner>
    </Wrapper>
  </Container>
)

export default Disclaimer
